import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SessionService, SessionTabService } from '@iris/iris-authentication';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { timer } from 'rxjs';

import '../assets/js/footer.js';
import { UnsubscribeOnDestroyAdapter } from '@iris/iris-base';

@Component({
  selector: 'app-iris-navbar',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnDestroy
{
  activeSession = false;

  constructor(
    private sessionService: SessionService,
    private sessionTabService: SessionTabService,
  ) {
    super();
  }

  ngOnInit() {
    this.configureAutoRefreshToken();
    this.activeSession = this.sessionTabService.preventNewTabSession();
    if (this.activeSession) {
      this.sessionService.activateKeepAlive();
    }
  }

  ngOnDestroy(): void {
    if (this.activeSession) {
      this.sessionTabService.removeActiveTabStatus();
      this.sessionService.inactivateKeepAlive();
    }
  }

  @HostListener('window:unload')
  beforeUnloadHandler() {
    if (this.activeSession) {
      this.sessionTabService.removeActiveTabStatus();
      this.sessionService.inactivateKeepAlive();
    }
  }

  private configureAutoRefreshToken() {
    const jwt = jwt_decode(this.sessionService.credentials.token) as any;

    const expireAt = moment.unix(jwt.exp);
    const now = moment();

    this.subs.add(
      timer(
        expireAt.subtract(15, 'seconds').diff(now, 'milliseconds'),
      ).subscribe(() => {
        this.sessionService.forceRefreshToken().then(() => {
          this.configureAutoRefreshToken();
        });
      }),
    );
  }
}
