<iris-modal [isShowing]="isShowing">
  <div class="row">
    <div class="col align-center">
      <img [src]="'icons/illustrated-icons/alerts/ic-logout.svg' | asset" />
    </div>
  </div>
  <div class="row">
    <div class="col align-center">
      <h6>{{ 'Logout.LogoutConfirmation' | translate }}</h6>
    </div>
  </div>
  <div class="row">
    <div class="col align-center cancel">
      <a href="#" (click)="cancel()">
        {{ 'Base.Cancel' | translate }}
      </a>
    </div>
    <div class="col align-center">
      <button type="button" class="btn btn-primary" (click)="logout()" class="btn btn-primary">
        {{ 'Base.Logout' | translate }}
      </button>
    </div>
  </div>
</iris-modal>
