import { Component } from '@angular/core';

@Component({
  selector: 'app-vigilado-img',
  templateUrl: './vigilado-img.component.html',
  styleUrls: ['./vigilado-img.component.scss']
})
export class VigiladoImgComponent { 

}
