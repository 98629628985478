<button
  class="navbar-toggler sidebar-toggler"
  (click)="toggleMobileMenu()"
  type="button"
>
  <span class="navbar-toggler-icon"></span>
</button>

<div *ngIf="showMobileMenu" class="menu-drawer">
  <a href="#" (click)="showMobileMenu = false" class="close-drawer"> </a>
  <div class="container-fluid d-flex flex-column content-wrapper">
    <div class="row drawer-header">
      <p class="drawer-title">
        {{ groupName }} <br />
        IP {{ me.lastAccessLocation }}
      </p>
    </div>

    <div class="row drawer-content">
      <div class="col px-0 d-flex flex-column justify-content-between">
        <div class="row">
          <div class="col-12 pt-0 menu noselect">
            <hr class="mt-0">
          </div>
          <ng-container *ngFor="let navItem of navItems$ | async">
            <div
              *ngIf="navItem.hasAccess"
              class="col-12 menu noselect"
              href="#"
              [ngClass]="{ active: isActive(navItem) }"
              (click)="navigate(navItem)"
            >
            <img
              [src]="navItem.icon | asset"
              alt="{{ 'NavBar.' + navItem.title + '.Title' | translate }}"
            />
            <span>
              {{ 'NavBar.' + navItem.title + '.Title' | translate }}
            </span>
            <hr>
            </div>
          </ng-container>
        </div>
        <div class="footer-drawer">
          <div class="row access-time">
            <div class="col align-self-center mt-5">
              <p class="mb-0 localization-text">
                {{ 'Base.LastLoginDate' | translate }}:
                {{ me.lastLoginDate | date: 'dd/MM/yyyy hh:mm a' }}
              </p>
              <p class="mb-0 localization-text">
                {{ 'Base.CurrentLoginDate' | translate }}:<iris-clock
                ></iris-clock>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-center mt-3">
              <button
                class="submit"
                class="btn btn-sm btn-primary text-white"
                (click)="logout.emit()"
              >
                {{ 'Base.Logout' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="showMobileMenu"
  (click)="showMobileMenu = false"
  class="modal-backdrop fade show"
></div>
