import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Group } from 'src/lib/models/group.model';
import { shareReplay } from 'rxjs/operators';
import { BaseService } from '../base/base.service';

@Injectable({ providedIn: 'root' })
export class GroupService extends BaseService {
  private groupCache$: Observable<Group>;

  get(): Observable<Group> {
    if (!this.groupCache$) {
      this.groupCache$ = this.fetchGroup().pipe(shareReplay());
    }

    return this.groupCache$;
  }

  private fetchGroup() {
    return this.http.get<Group>(`${this.settings.get.groupApi}`);
  }
}
