import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from '@iris/iris-authentication';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  timer: any;
  events = ['load', 'mousemove', 'keypress'];
  el: Window | Element;

  constructor(private sessionService: SessionService) {}

  resetTimer = () => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.sessionService.signOut().finally(() => {
        window.location.href = '/login?idle=true';
        this.terminate();
      });
    }, environment.idleTimeout * 60 * 1000);
  };

  public configure() {
    this.el = window;

    this.events.forEach(e => {
      this.el.addEventListener(e, this.resetTimer, false);
    });
  }

  public terminate() {
    clearTimeout(this.timer);

    this.events.forEach(e => {
      this.el.removeEventListener(e, this.resetTimer, false);
    });
  }
}
