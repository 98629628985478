import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss'],
})
export class LogoutConfirmationComponent implements OnInit {
  isShowing: boolean = false;
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {}

  show() {
    this.isShowing = true;
    this.changeDetector.detectChanges();
  }

  cancel() {
    this.isShowing = false;
    this.changeDetector.detectChanges();
    return false;
  }

  async logout() {
    window.location.href = '/login';
  }
}
