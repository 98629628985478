import { Injectable } from '@angular/core';
import { SessionService } from '@iris/iris-authentication';
import { menus } from 'src/lib/models/menus.model';
import { NavItem, Submenu } from 'src/lib/models/navigation.model';
import { UserHasPermissionService } from '../common/user-has-permission.service';

@Injectable({ providedIn: 'root' })
export class MenuService {

  complementsSetFocus = new Map<string, string>([
    ['/complements/irispay', '/irispay'],
  ]);

  constructor(
    private hasPermissionService: UserHasPermissionService,
    private authService: SessionService,
  ) {
    this.handleUserPermissions();
  }

  handleUserPermissions() {
    menus.map(item => {
      if (item.permissions?.length > 0) {
        item.hasAccess = this.hasPermissionService.hasPermission(
          item.permissions
        );
      }
      item.submenu?.forEach(async submenu => {
        submenu.items.forEach(item =>{
          if (item.permissions?.length > 0) {
            item.hasAccess = this.hasPermissionService.hasPermission(item.permissions);
          }
        });
      });
    });
  }

  async getMenus(): Promise<NavItem[]> {
    await this.authService.refreshToken();
    this.handleUserPermissions();
    return menus;
  }

  async getSubMenu(menuTitle: string): Promise<Submenu[]> {
    this.handleUserPermissions();
    return menus.find(menu => menu.title === menuTitle).submenu;
  }
}
