export const environment = {
  production: true,
  idleTimeout: 5,
  accountApi: 'https://api.dev.irsbnk.co/empresas/account',
  groupApi: 'https://api.dev.irsbnk.co/empresas-financial/groups',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas/login',
  complementsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket',
  staticApiKey: 'sbzctHBO6Z8u5AtmnKlRb8lTkUmSzwYU6JuW1Nwg',
  sessionConfiguration: {
    domain: 'empresas.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  termsAndConditionsURL: 'https://www.iris.com.co/centro-legal/terminos-y-condiciones',
  metricsApi:'https://api.dev.irsbnk.co/empresas-complements/metrics',
  addOnsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
  financialConsumerURL:'https://www.iris.com.co/sitio-institucional/redirecciones/defensor-del-consumidor-financiero-defensor-del-consumidor/'
};
