import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleFirstViewDate } from 'src/lib/models/moduleFirstViewDate.model';
import { BaseService } from '../base/base.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({ providedIn: 'root' })
export class AccountsService extends BaseService {
  constructor(protected http: HttpClient, protected settings: SettingsService) {
    super(http, settings);
  }

  updateFirstViewDates(
    moduleFirstViewDates: ModuleFirstViewDate[],
  ): Observable<any> {
    return this.http.patch(
      this.settings.get.accountApi + '/me',
      [
        {
          op: 'REPLACE',
          path: 'moduleFirstViewDates',
          value: moduleFirstViewDates,
        },
      ],
      {
        headers: {
          'content-type': 'application/json-patch+json',
        },
      },
    );
  }
}
