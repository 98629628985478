import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';
import { shareReplay } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';
import { Complement } from './complements.types';

@Injectable({ providedIn: 'root' })
export class ComplementsService extends BaseService {
  complementsCaches$: Observable<Complement[]>;

  constructor(protected http: HttpClient, protected settings: SettingsService) {
    super(http, settings);
  }

  get(): Observable<Complement[]> {
    if (!this.complementsCaches$) {
      this.complementsCaches$ = this.fetchComplements().pipe(shareReplay());
    }

    return this.complementsCaches$;
  }

  private fetchComplements(): Observable<Complement[]> {
    return this.http.get<Complement[]>(
      `${this.settings.get.complementsApi}/products/SAVINGS_ACCOUNT/complements`,
    );
  }
}
