import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '@iris/iris-authentication';
import { UnsubscribeOnDestroyAdapter } from '@iris/iris-base';
import { ItemSubmenu, Submenu } from 'src/lib/models/navigation.model';
import { MenuService } from 'src/lib/services/menu/menu.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  @Input() titleNavitem: string;
  @Output() selected = new EventEmitter<string>();

  subMenu: Promise<Submenu[]>;
  userId: string;

  constructor(
    private menuService: MenuService,
    private accountService: AccountService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subMenu = this.menuService.getSubMenu(this.titleNavitem);
    this.addUserIdProfile();
  }

  addUserIdProfile() {
    this.subs.add(
      this.accountService.me().subscribe(r => (this.userId = r.userId)),
    );

    this.subMenu.then(submenuItems => {
      submenuItems.forEach(i => {
        if (i.title === 'User') {
          const item = i.items.find(r => r.href === '/user-management/users');
          if (item) {
            item.href += `/${this.userId}`;
          }
        }
      });
    });
  }

  navigateSubmenu(route: ItemSubmenu) {
    if (this.verifyUrlExternal(route.href)) {
      this.openUrlExternal(route.href);
      return;
    }
    const customEvent = new CustomEvent('onChangeRoute', {
      detail: { route: route.href },
    });
    window.dispatchEvent(customEvent);
    this.selected.emit('active');
  }

  verifyUrlExternal(url: string) {
    return url.startsWith('https://');
  }

  openUrlExternal(url: string): void {
    window.open(url, '_blank');
  }

  permissionSubmenu(items: ItemSubmenu[]): boolean {
    return items.some(item => item.hasAccess);
  }
}
