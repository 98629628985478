<div *ngIf="me$ | async as me" class="c-app">
  <div *ngIf="group$ | async as group" class="c-wrapper" cdkScrollable>
    <header class="c-header d-none d-lg-block">
      <div class="border-bottom d-flex align-items-center">
        <div class="container d-flex justify-content-between">
          <div>
            <ng-container *ngIf="me$ | async as me">
              <p class="mb-0 text-white localization-text ml-1">
                {{ 'Base.Welcome' | translate }} {{ me.fullName }}!
              </p>
            </ng-container>
          </div>
          <div class="d-flex">
            <p class="mb-0 localization-text mr-5">
              {{ 'Base.LastLoginDate' | translate }}:
              {{ me.lastLoginDate | date: 'dd/MM/yyyy hh:mm a' }}
            </p>
            <p class="mb-0 localization-text">
              {{ 'Base.CurrentLoginDate' | translate }}:
              <iris-clock></iris-clock>
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-2 pt-4">
        <div class="row d-flex justify-content-between m-0 mb-2 row-navbar-logo">
          <div style="margin-right: auto">
            <img
              class="logo"
              [src]="'img/logos/Logo-Iris.svg' | asset"
              alt="IRIS LOGO"
            />
          </div>

          <div class="text-right align-self-center mb-4">
            <button
              class="submit btn btn-sm btn-primary text-white mb-2"
              (click)="logout()"
            >
              {{ 'Base.Logout' | translate }}
            </button>
          </div>
        </div>
      </div>

      <app-menu></app-menu>
    </header>

    <div class="d-block d-lg-none logo-wrapper text-center">
      <img
        class="logo-mobile"
        [src]="'img/logos/Logo-Iris.svg' | asset"
        alt="IRIS LOGO"
      />

      <app-mobile-menu
        [me]="me"
        [groupName]="group.groupName"
        (logout)="logout()"
      ></app-mobile-menu>
    </div>
  </div>
</div>

<app-logout-confirmation #logoutModal></app-logout-confirmation>
<iris-network-issues></iris-network-issues>
