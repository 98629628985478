import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';
import { AddOns, AddOnsTitle, Metrics } from './metrics.types';
import { AddOnsService } from 'src/lib/services/addOns/add-ons.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';
@Injectable({
  providedIn: 'root',
})
export class MetricsService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected settings: SettingsService,
    private addOnsService: AddOnsService,
  ){ super(http, settings); }

  metrics(origin, description): Observable<any> {
    return this.http.post<any>(`${this.settings.get.metricsApi}`,
    {
      "origin": origin,
      "description": description
    }
    );
  }

  registrerMetrics(addons:string) {
    switch (addons) {
      case AddOnsTitle.IrisCard:
        this.haveComplement(AddOns.IrisCard)
        break;
      case AddOnsTitle.IrisPay:
        this.haveComplement(AddOns.IrisPay)
        break;
      default:
        break;
    }

  }

  async haveComplement(addons:string) {
    const complement = await this.addOnsService.isActive(addons).toPromise();
    if(!complement) this.metrics(addons, Metrics.LEAD).toPromise();
  }
}
