import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { IrisBaseModule } from '@iris/iris-base';
import { VigiladoImgComponent } from './components/vigilado-img/vigilado-img.component';



@NgModule({
  declarations: [
    FooterComponent,
    VigiladoImgComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    IrisBaseModule
  ],
  exports: [FooterComponent]
})
export class FooterModule { }
