import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavItem } from 'src/lib/models/navigation.model';
import { AccountMe } from '@iris/iris-authentication';
import { MetricsService } from 'src/lib/services/metrics/metrics.service';
import { NavItemMobile } from 'src/lib/models/navigation-mobile.models';
import { MenuServiceMobile } from 'src/lib/services/menu/menu-mobile.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input() showMobileMenu: boolean = false;
  @Input() me: AccountMe;
  @Input() groupName: string;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  navItems$: Promise<NavItemMobile[]>;

  constructor(
    private menuService: MenuServiceMobile,
    private metricsService: MetricsService
    ) {}

  ngOnInit() {
    this.navItems$ = this.menuService.getMenus();
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  navigate(route: NavItem) {
    history.pushState(null, document.title, route.href);
    this.showMobileMenu = false;
    this.metricsService.registrerMetrics(route?.title);
  }

  isActive(route: NavItem) {
    return window.location.pathname.startsWith(route.href);
  }
}