<div class="d-flex justify-content-between submenu-contain p-0">
  <ng-container *ngFor="let item of subMenu | async">
    <div class="text-left" *ngIf="permissionSubmenu(item.items)">
      <h5>
        {{ 'NavBar.SubMenu.' + titleNavitem + '.' + item.title + '.Title' | translate }}
      </h5>
      <ng-container *ngFor="let list of item.items">
        <div *ngIf="list.hasAccess" class="container d-flex item-list">
          <div class="row" (click)="navigateSubmenu(list)">
            <div class="col-1">
              <img
                class="img-list"
                [src]="'img/submenu/box-submenu.svg' | asset"
                alt="img-list"
              />
            </div>
            <div class="col">
              <p class="p-0 m-0">
                {{ 'NavBar.SubMenu.' + titleNavitem + '.' + list.title + '.Title' | translate }}
              </p>
              <span>
                {{ 'NavBar.SubMenu.' + titleNavitem + '.' + list.title + '.Subtitle' | translate }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="contain-img flex-column">
    <div class="img-submenu">
      <img [src]="'img/submenu/img-submenu.svg' | asset" alt="img-submenu" />
    </div>
  </div>
</div>
