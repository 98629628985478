import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService, SessionService } from '@iris/iris-authentication';
import { SpinnerComponent, UnsubscribeOnDestroyAdapter } from '@iris/iris-base';
import { LogoutConfirmationComponent } from './logout-confirmation/logout-confirmation.component';
import { GroupService } from 'src/lib/services/group/group.service';
import { IdleService } from 'src/lib/services/idle/idle.service';
import { Group } from 'src/lib/models/group.model';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateLayoutComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnDestroy
{
  spinnerComponent = SpinnerComponent;
  me$: Observable<any>;
  group$: Observable<Group>;
  showNetworkIssuesModal = false;

  ignoreSpinnerOnUrls = ['dailybalances', 'transactions/summary'];

  @ViewChild('logoutModal') logoutModal: LogoutConfirmationComponent;

  constructor(
    private accountService: AccountService,
    private groupService: GroupService,
    private idleService: IdleService,
    private sessionService: SessionService,
  )
  {
    super();
  }

  ngOnInit(): void {
    if (this.sessionService.credentials?.token) {
      this.me$ = this.accountService.me();
      this.group$ = this.groupService.get();
      this.idleService.configure();
    }
  }

  ngOnDestroy(): void {
    this.idleService.terminate();
  }

  logout() {
    this.logoutModal.show();
  }
}
