<div class="app-menu container">
  <div class="app-menu__options">
    <ng-container *ngFor="let navItem of navItems; let i = index">
      <div
        *ngIf="navItem.hasAccess"
        class="menu noselect"
        id="menu-{{ i }}"
        href="#"
        [ngClass]="{ active: navItem.active }"
        (mouseenter)="enterMenu()"
      >
        <div class="menu__data" (click)="navigate(navItem)">
          <label *ngIf="navItem.title === 'IrisPay'" class="new-feature">
            {{ 'NavBar.AditionalProducts' | translate }}
          </label>

          <img
            [src]="navItem.icon | asset"
            alt="{{ 'NavBar.' + navItem.title + '.Title' | translate }}"
          />

          <p>
            {{ 'NavBar.' + navItem.title + '.Title' | translate }}
          </p>

          <img
            class="new-navbar"
            *ngIf="navItem.highlight"
            [src]="'img/menu/ic-new-navbar.svg' | asset"
            alt="ic-new-navbar"
          />
        </div>

        <div class="menu__submenu" *ngIf="activeMenu(navItem.title)">
          <div class="menu__submenu__data">
            <app-submenu
              [titleNavitem]="navItem.title"
              (selected)="selectedSubmenu(navItem)"
            >
            </app-submenu>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>