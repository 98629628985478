const resize_ob = new ResizeObserver(function (entries) {
  // since we are observing only a single element, so we access the first element in entries array
  let rect = entries[0].contentRect;

  // current width & height
  let width = rect.width;

  if (width < 1200) {
    document.getElementById('footer-container').style.position =
      'absolute';
    document.getElementById('footer-container').style.top =
      150 + Math.max(...entries.map(el => el.contentRect.height)) + 'px';
    document.querySelector('.footer').style.position = 'relative';
  } else {
    document.querySelector('.footer').style.position = 'fixed';
    document.getElementById('footer-container').style.position =
      'relative';
    document
      .getElementById('footer-container')
      .style.removeProperty('top');
  }
});


// start observing for resize
const elements = [
  'single-spa-application:@iris/bancadigital',
  'single-spa-application:@iris/irispay',
  'single-spa-application:@iris/complementos',
  'single-spa-application:@iris/iriscard',
]

elements.forEach(el => {
    const app = document.getElementById(el);
    if (app) {
      resize_ob.observe(app);
    }
})
